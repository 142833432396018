
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import contacts from "@/core/data/contacts";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
}
interface RequestForm {
  playground: string;
}

export default defineComponent({
  name: "private-chat",

  setup() {
    const store = useStore();

    let advert = computed(() => {
      return store.getters.currentResultsPlayground;
    });
    const route = useRoute();

    const formData = ref<RequestForm>({
      playground: "",
    });


    const requestNewAdvert = async (values) => {
      console.log(" VAlues : ", values);
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }
      await store.dispatch(Actions.GET_PLAYGROUND, formData.value);
    };

    return {
      requestNewAdvert,
      formData,
      advert,
    };
  },
});
